import React from 'react';
// lib
import { useTranslation } from 'react-i18next';

const WinnerListHistory = ({
    history = []
}) => {
    let { t } = useTranslation();
    return (
        <div className="row mt-2" style={{ overflowX: "auto" }}>
            <table className="mt-2 table" style={{ minWidth: '500px' }}>
                <thead>
                    <tr className="bg-warning text-black text-center">
                        <th>{t("winnerListHistory.no")}</th>
                        <th>{t("winnerListHistory.phone")}</th>
                        <th>{t("winnerListHistory.bet_amount")}</th>
                        <th>{t("winnerListHistory.win_amount")}</th>
                    </tr>
                </thead>
                <tbody className="bg-black text-white text-center">
                    {
                        history?.length > 0 ? (
                            history?.map(({ phone,bet_amount,win_amount }, _index) => (
                                <tr key={_index}>
                                    <td>{_index + 1}</td>
                                    <td>{phone}</td>
                                    <td>{bet_amount}</td>
                                    <td>{win_amount}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">
                                    <h6 className="text-white py-3">No Data!</h6>
                                </td>
                            </tr>)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default WinnerListHistory