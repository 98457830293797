import { createContext, useReducer } from "react";
// utils
import { removeLocalStorage, retrieveLocalStorage, storeLocalStorage } from "../../utils/localStorage";
// actionTypes
import { CHANGE_USER_BALANCE, GAME_BAL_CHANGE, GAME_REF_AMT_CLAIM, GAME_TO_MAIN, MAIN_TO_GAME, SET_USER_DATA, USER_LOGOUT } from "../actions/actionTypes";

export const LoginContext = createContext(null);

const initialState = {
    userData: retrieveLocalStorage("user_info") || null
};

const reducer = (state, action) => {
    switch (action.type) {
        case SET_USER_DATA: {
            let userData = {
                ...state.userData,
                ...action.payload
            };
            storeLocalStorage("user_info", userData);
            return {
                ...state,
                userData
            }
        }
        case USER_LOGOUT: {
            removeLocalStorage("user_info");
            return {
                ...state,
                userData: null
            }
        }
        case GAME_BAL_CHANGE: {
            let userData = {
                ...state.userData,
                game_balance: action.payload.game_balance
            };
            storeLocalStorage("user_info", userData);
            return {
                ...state,
                userData
            }
        }
        case CHANGE_USER_BALANCE: {
            let updateUserData = {
                ...state.userData,
                balance: action.payload
            }
            // store or update
            storeLocalStorage("user_info", updateUserData);
            return {
                ...state,
                userData: updateUserData
            }
        }
        case MAIN_TO_GAME: {
            // minus from main balance
            // plus to game balance
            let updateUserData = {
                ...state.userData,
                balance: action.payload.balance,
                game_balance: action.payload.game_balance
            }
            // store or update
            storeLocalStorage("user_info", updateUserData);
            return {
                ...state,
                userData: updateUserData
            }
        }
        case GAME_TO_MAIN: {
            // minus from game balance
            // plus to main balance
            let updateUserData = {
                ...state.userData,
                balance: action.payload.balance,
                game_balance: action.payload.game_balance
            }
            // store or update
            storeLocalStorage("user_info", updateUserData);
            return {
                ...state,
                userData: updateUserData
            }
        }
        case GAME_REF_AMT_CLAIM: {
            let userData = {
                ...state.userData,
                balance: parseInt(action.payload.game_refer_amt) + parseInt(state.userData.balance),
                game_refer_amt: 0
            };
            storeLocalStorage("user_info", userData);
            return {
                ...state,
                userData
            }
        }
        default:
            return state;
    }
};

const LoginProvider = (props) => {
    const value = useReducer(reducer, initialState);
    return (
        <LoginContext.Provider value={value}>
            {props.children}
        </LoginContext.Provider>
    );
};

export default LoginProvider;
