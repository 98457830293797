import React, { useState } from 'react'
// lib
import { Link, NavLink } from 'react-router-dom'
import i18n from 'i18next';
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';
// images
import Logo from '../../asset/image/logo_shwe.jpg'
// comp
import HeaderMarquee from '../../components/shared_comp/HeaderMarquee'
// utils
import { moneyFormatter } from '../../utils/moneyFormatter'
import { CN, CURRENT_LANG, EN, MM, THAI } from '../../localization/langConfig';
import { removeLocalStorageNoJson } from '../../utils/localStorage';
// image
import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";

const FlagImage = ({
    flagImg,
    width
}) => {
    return (
        <img
            style={{ width, objectFit: 'contain' }}
            src={flagImg}
            alt='...'
        />
    )
}

const MobileHeader = ({
    marquee,
    userData,
    isDisabled,
    handleToggle,
    handleLogout
}) => {
    let location = useLocation();
    let { t } = useTranslation();
    const getLang = removeLocalStorageNoJson(CURRENT_LANG);
    const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
    const [openLang, setOpenLang] = useState(false);
    const changeLang = (lang) => {
        i18n.changeLanguage(lang);
        setchangeLanguage(lang);
        setOpenLang(false);
    }
    const toggleSideBar = () => {
        document.getElementById("offcanvasWithBothOptions").classList.toggle('show');
    }

    const navLinks = [
        {
            id: 0,
            title: 'home',
            to: "/"
        },
        {
            id: 1,
            title: 'winNoHistory',
            to: "/win-number"
        },
        {
            id: 2,
            title: 'winnerListHistory.title',
            to: "/winner-list"
        },
        {
            id: 3,
            title: 'history',
            to: "/betslip"
        }
    ]

    let chkAuth = userData?.token;
    return (
        <>
            <div className="d-flex position-relative d-lg-none justify-content-between align-items-center">
                {/* Menu */}
                <svg className='bg-white rounded-5' onClick={toggleSideBar} xmlns="http://www.w3.org/2000/svg" height="27px" viewBox="0 0 24 24" width="27px" fill="#E2A101">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                </svg>
                {/* Logo */}
                <Link to='/' >
                    <img className='shadow-lg rounded-circle border border-1 border-dark' src={Logo} style={{ height: "70px", marginLeft: 42 }} alt="..." />
                </Link>
                <div className="d-flex">
                    {/* Lang Switch */}
                    <div onClick={() => setOpenLang(!openLang)} className="d-flex cursor-pointer align-items-center px-2">
                        {language === EN ? <FlagImage width={35} flagImg={UKFlag} /> :
                            language === MM ? <FlagImage width={35} flagImg={MMFlag} /> :
                                language === CN ? <FlagImage width={35} flagImg={ChinaFlag} /> :
                                    <FlagImage width={35} flagImg={ThaiFlag} />}
                        {/* Expand Icon */}
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                        </svg>
                    </div>
                    {/* Login */}
                    {
                        chkAuth ? (
                            <div className="dropdown" style={{ cursor: "pointer" }}>
                                <svg className='rounded-circle' data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="27px" viewBox="0 0 24 24" width="27px" fill="#E2A101">
                                    <g>
                                        <rect fill="white" height="24" width="24" />
                                    </g>
                                    <g>
                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z" />
                                    </g>
                                </svg>

                                <ul style={{ zIndex: "10000" }} className="dropdown-menu border border-goldenrod bg-white  rounded-4" >
                                    <Link to='/profile' className="text-decoration-none ">
                                        <li className="profileBtn ps-3 py-1" style={{ color: "goldenrod" }}>
                                            {t("profile")}
                                        </li>
                                    </Link>
                                    <Link to='/cashin' className="text-decoration-none">
                                        <li className="profileBtn ps-3 py-1" style={{ color: "goldenrod" }}>
                                            {t("cashIn")}
                                        </li>
                                    </Link>
                                    <Link to='/cashout' className="text-decoration-none">
                                        <li className="profileBtn ps-3 py-1" style={{ color: "goldenrod" }}>
                                            {t("cashOut")}
                                        </li>
                                    </Link>
                                    <Link onClick={handleLogout} className="text-decoration-none">
                                        <li className="profileBtn ps-3 py-1" style={{ color: "goldenrod" }}>
                                            {t("logout")}
                                        </li>
                                    </Link>
                                </ul>
                            </div>
                        ) : (
                            <Link to="/login" className="text-decoration-none btn bg-white rounded-pill">
                                <span className='fw-bold' style={{ color: "#E2A101" }}>{t("login")}</span>
                            </Link>
                        )
                    }
                </div>
                {/* Land Switch: DropDown  */}
                <div className={`position-absolute ${openLang ? 'd-flex' : 'd-none'} flex-column gap-1 bg-white rounded-3 p-2`}
                    style={{ right: 7, top: 52, zIndex: 9999 }}>
                    <div
                        className={`${language !== MM ? 'd-flex' : 'd-none'} gap-2 align-items-center cursor-pointer`}
                        onClick={() => changeLang(MM)}
                    >
                        <FlagImage width={25} flagImg={MMFlag} />
                        <span>ဗမာစာ</span>
                    </div>
                    <div
                        className={`${language !== EN ? 'd-flex' : 'd-none'} gap-2 align-items-center cursor-pointer`}
                        onClick={() => changeLang(EN)}
                    >
                        <FlagImage width={25} flagImg={UKFlag} />
                        <span>English</span>
                    </div>
                    <div
                        className={`${language !== THAI ? 'd-flex' : 'd-none'} gap-2 align-items-center cursor-pointer`}
                        onClick={() => changeLang(THAI)}
                    >
                        <FlagImage width={25} flagImg={ThaiFlag} />
                        <span>ไทย</span>
                    </div>
                    <div
                        className={`${language !== CN ? 'd-flex' : 'd-none'} gap-2 align-items-center cursor-pointer`}
                        onClick={() => changeLang(CN)}
                    >
                        <FlagImage width={25} flagImg={ChinaFlag} />
                        <span>中国人</span>
                    </div>
                </div>
            </div>
            <div className="d-flex d-lg-none mb-0 mb-md-2 align-items-center">
                <HeaderMarquee marquee={marquee} />
            </div>
            {/* Mobile SideBar */}
            <div data-bs-backdrop="false" style={{backgroundColor : "#9cabb9"}} className="offcanvas offcanvas-start   border-end border-goldenrod text-white d-lg-none" data-bs-scroll="true" tabIndex={-1} id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title text-black" id="offcanvasWithBothOptionsLabel">Close</h5>
                    <button type="button" className="btn-close btn-close-black" onClick={toggleSideBar}></button>
                </div>
                <div className="offcanvas-body d-flex flex-column gap-2">
                    {
                        chkAuth && (
                            <div className="d-flex mb-2 flex-column gap-2">
                                {/* Main & Game */}
                                <div className="d-flex justify-content-center flex-column gap-2 position-relative">
                                    {/* Main  */}
                                    <div className="d-flex flex-column gap-1 p-3 bg-info text-black rounded-3">
                                        <h6>{t("mainBalance")}</h6>
                                        <div className="d-flex align-items-center justify-content-between">
                                            {/* Main Balance */}
                                            <h5>{userData?.balance ? moneyFormatter(parseInt(userData?.balance || 0)) : 0} Ks</h5>
                                            {/* Svg Icon */}
                                            <svg className="pt-2" fill="goldenrod" xmlns="http://www.w3.org/2000/svg" height={50} width={23}>
                                                <path d="M0 0h24v24H0z" fill="none" />
                                                <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        )
                    }
                    {/* Nav-Link Lists */}
                    <div className="d-flex flex-column gap-2 mobile-side-bar">
                        {
                            navLinks?.map(({ id, title, to }) => (
                                <Link key={id} onClick={toggleSideBar} to={to} className={`d-flex w-100 border border-dark text-decoration-none text-black align-items-center justify-content-between p-3 mb-2 gap-2 ${location.pathname === to ? "main-bg" : 'bg-info'} rounded-3`}>
                                    <span className="text-uppercase" style={{ fontSize: "15px" }}>{t(title)}</span>
                                    {/* Right Arrow */}
                                    <svg fill="black" xmlns="http://www.w3.org/2000/svg" height={24} width={24}>
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z" />
                                    </svg>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileHeader