import React from "react";
// lib
import { ClockLoader } from "react-spinners";

const Loading = ({
  loading = false
}) => {
  return (
    <div className="my-auto d-flex justify-content-center">
      <ClockLoader
        color="#E2A101"
        loading={loading}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}

export default Loading