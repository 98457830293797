import React, { useState } from "react";
// utils
import { imageError } from "../../utils/errors";
// images
import googlePlay from "../../asset/image/google-play.svg";
import mediaFire from "../../asset/image/media-fire.jpg";
// Url
import { BaseURL } from "../../api/apiUrl";

const DownloadLink = ({
  forMobile = false,
  socialLink = null
}) => {
  const [openAppLink, setOpenAppLink] = useState(true);

  let links = [
    // Play Store
    {
      id: 1,
      image: googlePlay,
      url: socialLink?.play_store || null,
      is_qr_code: false
    },
    // Qr Scan
    {
      id: 2,
      image: socialLink?.qr ? `${BaseURL}/${socialLink?.qr}` : "http://",
      url: null,
      is_qr_code: true
    },
    // Media Fire
    {
      id: 3,
      image: mediaFire,
      url: socialLink?.media_fire || null,
      is_qr_code: false
    }
  ];

  const handleToggle = () => {
    document.getElementById("qr-code-scan").classList.toggle('active');
  }

  if (forMobile)
    return (
      <div className={`${openAppLink ? 'd-flex' : 'd-none'} d-md-none align-items-center justify-content-between bg-dark bg-opacity-75 shadow-lg py-2`}>
        <div className="d-flex align-items-center gap-3 ps-3">
          {links?.map(({ id, image, is_qr_code, url }) => (
            <a
              href={url}
              key={id}
              target="_blank"
              onClick={() => {
                if (is_qr_code && image !== "http://") handleToggle();
              }}
              className='shadow-lg'
            >
              <img
                src={image}
                alt="..."
                style={{ width: "43px", height: "43px", objectFit: "cover" }}
                onError={imageError}
                className="d-block rounded-2"
              />
            </a>
          ))}
        </div>
        <button onClick={() => setOpenAppLink(false)} type="button" className="btn-close btn-close-black fw-bold me-3" />
      </div>
    )

  // For Website
  return (
    <div className="fixed-at-side shadow fixed-at-side--l d-none d-md-flex flex-column gap-2 py-2 d-sm-none px-2 bg-dark bg-opacity-75 shadow-lg text-center text-white">
      {links?.map(({ id, image, is_qr_code, url }) => (
        <a
          href={url}
          key={id}
          target="_blank"
          onClick={() => {
            if (is_qr_code && image !== "http://") handleToggle();
          }}
          className='shadow-lg'
        >
          <img
            src={image}
            alt="..."
            style={{ width: "43px", height: "43px", objectFit: "cover" }}
            onError={imageError}
            className="d-block rounded-2"
          />
        </a>
      ))}
    </div>
  );
};

export default DownloadLink;
