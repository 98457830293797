// Api Header
import { getConfig } from "./apiHeader";
// Api URL
import {
    GetCashInHistoryURL,
    GetCashOutHistoryURL,
    GetCTwoDHistoryURL,
    GetThreeDHistoryURL,
    GetTwoDHistoryURL,
    GetWinNumberHistoryURL,
    GetWinnerListHistoryURL
} from "./apiUrl";
// Lib
import moment from "moment";

export async function getHistoryApi({
    token,
    signal = null,
    type = '2d',
    from = new Date(),
    to = new Date(),
}) {
    // formate date to "YYYY-MM-DD"
    let start_date = moment(from).format("YYYY-MM-DD");
    let end_date = moment(to).format("YYYY-MM-DD");
    let urlArray = {
        'c2d': GetCTwoDHistoryURL,
        '2d': GetTwoDHistoryURL,
        '3d': GetThreeDHistoryURL,
        'cashin': GetCashInHistoryURL,
        'cashout': GetCashOutHistoryURL,
    };
    const res = await fetch(`${urlArray[type]}?start_date=${start_date}&end_date=${end_date}`, {
        ...getConfig,
        signal,
        headers: {
            ...getConfig?.headers,
            'Authorization': `Bearer ${token}`
        }
    })
    if (res.ok) return res.json()
    else throw res
}



export async function getWinNumberHistoryApi({
    signal = null,
    type = '2D',
    from = new Date(),
    to = new Date()
}) {
    // formate date to "yyyy-mm-dd"
    let start_date = moment(from).format("YYYY-MM-DD");
    let end_date = moment(to).format("YYYY-MM-DD");
    let url = `${GetWinNumberHistoryURL}/${type}?start_date=${start_date}&end_date=${end_date}`;
    const res = await fetch(url, {
        ...getConfig,
        signal
    })
    if (res.ok) return res.json()
    else throw res
}

export async function getWinnerListHistoryApi({
    signal = null,
    type = '2D',
}) {
    let url = `${GetWinnerListHistoryURL}/${type}`;
    const res = await fetch(url, {
        ...getConfig,
        signal
    })
    if (res.ok) return res.json()
    else throw res
}