import React, { lazy, Suspense } from "react";
// lib
import { Routes, Route } from "react-router-dom";
// comp
import Loading from "../components/shared_comp/Loading";
import Protected from "../components/shared_comp/Protected";
import NotFound from "../pages/NotFound";
import WinnerList from "../pages/WinnerList";

// pages
const Home = lazy(() => import("../pages/Home"));
const TwoLottery = lazy(() => import("../pages/TwoLottery"));
const ThreeLottery = lazy(() => import("../pages/ThreeLottery"));
const Profile = lazy(() => import("../pages/Profile"));
const History = lazy(() => import("../pages/History"));
const CashIn = lazy(() => import("../pages/CashIn"));
const CashOut = lazy(() => import("../pages/CashOut"));
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const ForgotPass = lazy(() => import("../pages/ForgotPass"));
const WinNumber = lazy(() => import("../pages/WinNumber"));

const MainRoute = ({
  socialLink= null
}) => {
  return (
    <Suspense fallback={<Loading loading={true} />}>
      <Routes>
        {/* Auth Routes */}
        <Route path="/profile" element={<Protected><Profile /></Protected>} />
        <Route path="/cashin" element={<Protected><CashIn /></Protected>} />
        <Route path="/cashout" element={<Protected><CashOut /></Protected>} />
        <Route path="/two/lottery" element={<TwoLottery />} />
        <Route path="/three/lottery" element={<ThreeLottery />} />
        <Route path="/betslip" element={<Protected><History /></Protected>} />
        {/* No Auth Routes */}
        <Route path="/" element={<Home socialLink={socialLink} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/win-number" element={<WinNumber />} />
        <Route path="/winner-list" element={<WinnerList />} />
        {/* All Games */}
        {/* No Match Routes  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
