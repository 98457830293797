import { useContext, useEffect, useState } from "react";
// lib
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// layouts
import Header from "./layout/Header";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";
// routes
import MainRoute from "./routes/MainRoutes";
// css
import "./asset/css/home.css";
// utils
import { toastError } from "./utils/toast";
// context
import { LoginContext } from "./store/context/LoginProvider";
// actionTypes
import { GAME_BAL_CHANGE, SET_USER_DATA, USER_LOGOUT } from "./store/actions/actionTypes";
// Api
import { getProfileApi } from "./api/profileApi";
import { getHeaderTextApi, getSocialLinkApi } from "./api/homeApi";
// Firebase
import { database, messaging, onMessage, onValue, ref } from "./firebase/myvip_firebase";
import Privacy from "./pages/Privacy";

function App() {
  const [marquee, setMarquee] = useState("");
  const [socialLink, setSocialLink] = useState({
    facebook: "",
    viber: "",
    messenger: "",
    instagram: "",
    play_store: "",
    qr: ""
  })
  // context
  const [{ userData }, dispatch] = useContext(LoginContext);
  // react router dom
  let navigate = useNavigate();
  let location = useLocation();
  let path_name = location?.pathname;
  let is_game_view = location?.state?.is_game_view;

  useEffect(() => {
    // Fetch Social Link
    getSocialLinkApi()
      .then(setSocialLink)
    // Fetch Header Text
    getHeaderTextApi()
      .then(setMarquee)
    // Firebase Noti: Foreground
    try {
      onMessage(messaging, (payload) => {
        const { title, body, icon } = payload.notification;
        const option = {
          body,
          icon
        };
        new Notification(title, option);
      })
    } catch (error) {
      console.log("Firebase Noti Error:", error);
    }
    // Game Balance From Firebase
    try {
      if (userData?.id) {
        let refer = ref(database, `users/balances/${userData?.id}`);
        onValue(refer, snapshot => {
          let { last_balance } = snapshot.val();
          // Save or Update Game Balance
          dispatch({
            type: GAME_BAL_CHANGE,
            payload: {
              game_balance: last_balance || 0
            }
          })
        });
      }
    } catch (error) {
      console.log("Firebase Game Balance:", error);
    }
    // Firebase Noti: Background
    try {
      //BroadcastChannel to recieve background messaging
      const channel = new BroadcastChannel("myvip_broadcast_channel");
      channel.onmessage = (event) => {
        let { title, body, icon } = event.data.key?.data;
        //notification body
        const options = {
          body,
          icon,
          vibrate: true, //vibrate will work only on the mobile device
        };
        new Notification(title, options)
      };
    } catch (error) {
      console.log("Broadcast Error:");
    }
  }, [])

  useEffect(() => {
    if (userData) {
      // Fetch Profile
      getProfileApi({ token: userData?.token })
        .then(res => {
          let payload = {
            ...userData,
            lvl_2: res?.data?.lvl_2,
            balance: res?.data?.balance,
            user_code: res?.data?.user_code,
            refer_count: res?.data?.refer_count,
            game_refer_amt: res?.data?.game_refer_amt,
            turnover: res?.data?.turnover
          };
          // Save or Update Profile Data
          dispatch({
            type: SET_USER_DATA,
            payload
          })
        })
        .catch(err => {
          if (err?.status === 401) {
            toastError("Session Expired, Please Login Again!");
            dispatch({
              type: USER_LOGOUT
            })
            navigate("/login")
          }
        })
    }
  }, [path_name]);

  return (
    <div className="min-vh-100 d-flex flex-column">
      {
        location.pathname ==='/privacy' ?
        <Routes>
          <Route path="/privacy" element={<Privacy/>}/>
        </Routes> :
        <>
        {/* Noti Show */}
      <ToastContainer />
      {!is_game_view && (
        <>
          <Header marquee={marquee} socialLink={socialLink} />
          <Navbar />
        </>
      )}
      {/* Content */}
      <main className="bs-flex d-flex flex-column">
        <MainRoute socialLink={socialLink} />
      </main>
      {!is_game_view && <Footer socialLink={socialLink} />}
        </>
      }
    </div>
  );
}

export default App;
